import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { TweenMax } from "gsap/all"


import "../styles/image-slide.css"

const ImagesSlide = (props) => {

const data = useStaticQuery(graphql`
  query {
    allFile(filter: { extension: { in: ["jpg", "png", "jpeg"] } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 80
            placeholder: DOMINANT_COLOR
          )
        }
        name
        dir
        extension
      }
    }
  }
`)

// if (props.images.length === 0) {
//   console('no images provided')
//   return
// }

const imagesRefs = useRef([])
imagesRefs.current = []

const addToRefs = el => {
    if (el && !imagesRefs.current.includes(el)) {
      imagesRefs.current.push(el)
    }
};

const allReceivedImages = [...data?.allFile?.nodes]

let imagesToSearch = props.images

imagesToSearch.forEach((element, index) => { //remove extention
  imagesToSearch[index] =  element.replace(/\.[^/.]+$/, "")
});

let imagesToDisplay = []

imagesToSearch.forEach((element, index) => { //remove extention
  imagesToDisplay.push(allReceivedImages.filter(item =>
    imagesToSearch[index].includes(item.name)
  ))
});



useEffect(() => {
  const animConfig = {
    duration: 60,
    easing: "Linear.easeIn",
  }

  TweenMax.to(".image-slide", animConfig.duration, {
        ease: animConfig.easing,
        transform: 'translateX(-3000px)'
      })


})

  return (
    <>
      <div className="image-slide">
        {imagesToDisplay.map((item, index) => (
          <div className="item-container" key={index} ref={addToRefs}>
            <div className="item">
              <GatsbyImage
                image={getImage(imagesToDisplay[index][0].childImageSharp)}
                alt="graf"
              />
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

ImagesSlide.propTypes = {
  images: PropTypes.array,
}

export default ImagesSlide
