import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import { gsap } from "react-gsap"
import { gsap, TweenMax } from "gsap/all"


import "../styles/image-stack.css"

const ImagesStack = (props) => {

const data = useStaticQuery(graphql`
  query {
    allFile(filter: { extension: { in: ["jpg", "png", "jpeg"] } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            quality: 80
            placeholder: DOMINANT_COLOR
          )
        }
        name
        dir
        extension
      }
    }
  }
`)

// if (props.images.length === 0) {
//   console('no images provided')
//   return
// }

const imagesRefs = useRef([])
imagesRefs.current = []

const addToRefs = el => {
    if (el && !imagesRefs.current.includes(el)) {
      imagesRefs.current.push(el)
    }
};

const allReceivedImages = [...data?.allFile?.nodes]

let imagesToSearch = props.images

imagesToSearch.forEach((element, index) => { //remove extention
  imagesToSearch[index] =  element.replace(/\.[^/.]+$/, "")
});

let imagesToDisplay = []

imagesToSearch.forEach((element, index) => { //remove extention
  imagesToDisplay.push(allReceivedImages.filter(item =>
    imagesToSearch[index].includes(item.name)
  ))
});



useEffect(() => {
  const animConfig = {
    duration: 0.9,
    easing: "sine.in",
  }
  const imgs = [...imagesRefs.current]
  const next = 2

  gsap.set(imagesRefs.current[0], { autoAlpha: 1 })

  function crossfade() {
    var action = gsap.timeline()
   
    action
      .to(imgs[0], animConfig.duration, {
        ease: animConfig.easing,
        autoAlpha: 0,
      })
      .to(
        imgs[1],
        animConfig.duration,
        { ease: animConfig.easing, autoAlpha: 1 },
        0
      )

      imgs.push(imgs.shift())

      TweenMax.delayedCall(next, crossfade)
  }

  TweenMax.delayedCall(next, crossfade)


})

  return (
    <>
      <div className="image-stack">
        {imagesToDisplay.map((item, index) => (
          <div className="item-container" key={index} ref={addToRefs}>
            <div className="item">
              <GatsbyImage
                image={getImage(imagesToDisplay[index][0].childImageSharp)}
                alt="graf"
              />
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

ImagesStack.propTypes = {
  images: PropTypes.array,
}

export default ImagesStack
