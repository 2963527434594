import React from "react"
import PropTypes from "prop-types"
import "../styles/grid.css"

function Grid(props) {
  const receivedClasses = props.classes

  return (
    <div
      className={`grid-component ${receivedClasses && receivedClasses.join(" ")}`}
    >
      {props.children}
    </div>
  )
}

Grid.defaultProps = {
  classes: null,
}

Grid.propTypes = {
  classes: PropTypes.array,
}

export default Grid
