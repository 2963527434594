import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import defaultMetaImage from "../../static/socialcard.jpg"

function Seo({
  siteMetadata: seo,
}) {
  const image = seo.image || defaultMetaImage
  return (
    <>
      <Helmet>
        {/* General tags */}
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="image" content={image} />
        <link rel="apple-touch-icon" sizes="144x144" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        {/* OpenGraph tags */}
        <meta property="og:url" content={seo.url} />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={image} />
        <meta property="fb:app_id" content={seo.social.fbAppID} />

      </Helmet>
    </>
  )
}

function SEOWithQuery(props) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          organization {
            name
          }
          social {
            instagram
            fbAppID
          }
        }
      }
    }
  `)
  return <Seo siteMetadata={siteMetadata} {...props} />
}

export default SEOWithQuery
