import React, { useState } from "react"
import Axios from "axios"

import "../styles/form.css"
import PropTypes from "prop-types"

function Form(props) {


  const [errorMessage, setErrorMessage] = useState("")
  const [value, setValue] = useState("")
  const [policies, setPolicies] = useState(false)
  const [success, setSuccess] = useState(false)

  const submitValue = () => {

    if (policies && value) {
      setErrorMessage("")
      const objValue = {
        email: value,
      }
            
      Axios.get("/api/lead", {
        params: {
          email: objValue.email,
        },
      }).then(function (response) {
      // handle success
      setSuccess('ok')
      console.log(response);
      }).catch(function (error) {
        console.log(error)
      })

    } else {
      setErrorMessage(props.labels.error)
      console.log("error")
      return false
    }
    
  }

  console.log("policies", policies)

  return (
    <>
            {success &&
        <p className="my-2 text-sm">{props.labels.success}</p>
        }
      {!success &&
        

      <div className="form">
        <div className="email pb-2 mb-3">
          <input
            placeholder={props.labels.mail}
            value={value}
            onChange={e => {
              setValue(e.target.value)
              setErrorMessage("")
            }}
            type="email"
          ></input>
          <button onClick={submitValue}>{props.labels.send}</button>
        </div>
        {errorMessage &&
        <p className="my-2 text-sm">{errorMessage}</p>
        }

        <div className="check">
          <input
            onChange={e => setPolicies(e.target.checked)}
            className="checkbox mt-1"
            type="checkbox"
            checked={policies}
          ></input>
          <p className="text-gray text-left text-sm ml-2 w-11/12">
            {props.labels.conditions} <span></span>
            <a className="border-b-2 border-gray" href="/policies" target="_blank"
                rel="noreferrer">
              {props.labels.conditions2}
            </a>
          </p>
        </div>
      </div>
              }
    </>
  )
}


Form.propTypes = {
  labels: PropTypes.object,
}


export default Form
