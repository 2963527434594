import React from "react"
import PropTypes from "prop-types"
import "../styles/grid-block.css"

function GridBlock(props) {
  const receivedClasses = props.classes

  return (
    <div
      className={`grid-block ${
        receivedClasses && receivedClasses.join(" ")
      }`}
    >
      {props.children}
    </div>
  )
}

GridBlock.defaultProps = {
  classes: [''],
}

GridBlock.propTypes = {
  classes: PropTypes.array,
}

export default GridBlock