import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import "../styles/links.css"

function Links(props) {
	const receivedClasses = props.classes

  return (
    <div className={`links ${receivedClasses && receivedClasses.join(" ")}`}>
      <Link
        to="/"
        activeStyle={{
          color: "white",
          borderBottom: "2px solid white",
        }}
      >
        FR
      </Link>
      <Link
        to="/en/"
        activeStyle={{
          color: "white",
          borderBottom: "2px solid white",
        }}
      >
        EN
      </Link>
    </div>
  )
}

Links.defaultProps = {
  classes: [""],
}

Links.propTypes = {
  classes: PropTypes.array,
}

export default Links
